import React from 'react';
import "./footerbcon.css";

function Footerbcon () {
    return (
        <h6 className="copyRights">
            © {new Date().getFullYear()} | Dream of Falafel. All rights reserved.
        </h6>
    );
}

export default Footerbcon;